<template>
  <div class="dashboard__container" v-if="user">
    <div class="dashboard__container--body" v-if="user">
      <!-- <div class="dashboard__container--body--col">
        <UserEvents :events="userEvents" />
      </div>
      <div class="dashboard__container--body--col"> -->
        <UserAssignments :assignments="userAssignments" />
      <!-- </div> -->
    </div>
<Loader v-if="(userAssignments && userAssignments.length == 0)" />

    </div>

</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserAssignments from '@/components/Profile/UserAssignments.vue'
// import UserEvents from '@/components/Profile/UserEvents.vue'

export default {
  props: ['user'],
  name: 'userAssignments',
  //  mounted () {
  //   this.$store.dispatch('getUserAssignments', this.$route.params.id)
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'userAssignments']),
  },
  // created() {
  //   this.$store.dispatch('getUserAssignments', this.$route.params.id)
  //   // this.$store.dispatch('getUserEvents', this.$route.params.id)
  // },
  components: {
    Loader,
    UserAssignments,
    // UserEvents
  },
}

</script>